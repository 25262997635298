import { Button, IcFluentArrowUp24Regular, useEventListener } from '@flipgrid/flipkit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { scrollToTop } from '~/helper/helper';
import scrollToTopButtonStyles from '~/styles/components/Utility/Buttons/ScrollToTopButton.css';

export const links = () => [{ rel: 'stylesheet', href: scrollToTopButtonStyles }];

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState<boolean>(false);
  const { t } = useTranslation();

  const toggleVisibility = () => {
    if (window.pageYOffset > 750) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEventListener('scroll', toggleVisibility);

  const handleClick = () => {
    scrollToTop();
    const skipLink = document.getElementById('skipLinkTarget');
    if (skipLink) skipLink.focus();
  };

  if (!showButton) return null;

  return (
    <Button
      theme="secondary"
      aria-label={t('scrollToTopButton.scrollToTop')}
      className="scrollToTopButton"
      data-testid="scrollToTopButton__button__scroll"
      icon={<IcFluentArrowUp24Regular />}
      onClick={handleClick}
      size="52"
      variant="circle" />);


};

export default ScrollToTopButton;